<template>
    <div>
        <div class="modal fade" id="AppShowStats" tabindex="-1" role="dialog" aria-labelledby="AppShowStatsLabel"
             aria-hidden="true" ref="modal">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="AppShowStatsLabel">
                            Statistiche email {{name}}
                        </h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body p-0">
                        <div class="table-responsive">
                            <table class="table mb-0 table-striped table-hover">
                                <thead>
                                <tr>
                                    <th rowspan="2" style="vertical-align: middle">
                                        <i class="fas fa-link mr-2 text-muted"></i>
                                        Dominio
                                    </th>
                                    <th colspan="2" class="text-center p-1">
                                        <i class="fas fa-envelope mr-2 text-muted"></i> Email
                                    </th>
                                    <th colspan="2" class="text-center p-1">
                                        <i class="fas fa-file-invoice-dollar mr-2 text-muted"></i>
                                        Preventivi
                                    </th>
                                </tr>
                                <tr>
                                    <th class="p-1 text-center">
                                        <i class="fas fa-paper-plane mr-2 text-success"></i>
                                        Inviate
                                    </th>
                                    <th class="p-1 text-center">
                                        <i class="fas fa-times mr-2 text-danger"></i>
                                        Non lette
                                    </th>
                                    <th class="p-1 text-center">
                                        <i class="fas fa-paper-plane mr-2 text-success"></i>
                                        Inviati
                                    </th>
                                    <th class="p-1 text-center">
                                        <i class="fas fa-times mr-2 text-danger"></i>
                                        Non letti
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="domain in domains">
                                        <td>
                                            <strong>{{domain.domain}}</strong>
                                        </td>
                                        <td class="text-center">
                                            {{domain.total}}
                                        </td>
                                        <td class="text-center">
                                            {{domain.unread_percentage}} %
                                        </td>
                                        <td class="text-center">
                                            {{domain.quotes_total}}
                                        </td>
                                        <td class="text-center">
                                            {{domain.quotes_unread_percentage}} %
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">
                            Chiudi
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import EventBus from "../EventBus";

    export default {
        mounted() {
            EventBus.$on('stats:show', ({domain, latest_usage_stats}) => {
                this.domains = latest_usage_stats.data.outbound_emails.domains;
                this.name = domain;

                setTimeout(() => $(this.$refs.modal).modal('show'), 0);
            });
        },
        data() {
            return {
                domains: [],
                name: null,
            }
        },
        methods: {
        }
    }
</script>

<style lang="scss">

</style>