<template>
    <div v-if="app.latest_usage_stats">
        <ul class="small stats-list list-unstyled mb-0">
            <li
                v-if="moduleActive(app, 'quoting') && stats.quotes"
                :class="unreadAlertClass(stats.quotes.sent, unreadQuotesPercentage)"
                class="row-stats-index"
            >
                <div class="row-stats-index__label">Preventivi non letti</div>
                <div class="row-stats-index__value">
                    <div>{{unreadQuotesPercentage}} %</div>
                    <div class="row-stats-index__value--secondary">
                        {{unreadQuotes}} / {{stats.quotes.sent}}
                    </div>
                </div>
            </li>
            <li v-if="moduleActive(app, 'quoting')"
                class="row-stats-index"
                :class="unreadAlertClass(stats.outbound_emails.quotes_total, stats.outbound_emails.quotes_unread_percentage)">
                <div class="row-stats-index__label">Email preventivo non lette</div>
                <div class="row-stats-index__value">
                    <div>{{stats.outbound_emails.quotes_unread_percentage}} %</div>
                    <div class="row-stats-index__value--secondary">
                        {{unreadQuoteEmails}} / {{stats.outbound_emails.quotes_total}}
                    </div>
                </div>
            </li>
            <li
                v-if="moduleActive(app, 'quoting')"
                class="row-stats-index"
            >
                <div class="row-stats-index__label">
                    <div>
                        Email totali non lette
                        <a href="#" @click.prevent="showStats(app)" class="text-muted">
                            <i class="fa fa-info-circle"></i>
                        </a>
                    </div>
                </div>
                <div class="row-stats-index__value">
                    <div>{{stats.outbound_emails.unread_percentage}} %</div>
                    <div class="row-stats-index__value--secondary">
                        {{unreadEmails}} / {{stats.outbound_emails.total}}
                    </div>
                </div>
            </li>
        </ul>
        <details>
            <summary class="small p-1 text-muted">Altre statistiche</summary>
            <div>
                <ul class="small stats-list list-unstyled mb-0">
                    <li v-if="moduleActive(app, 'quoting')" class="row-stats-index">
                        <div class="row-stats-index__label">
                            Richieste non gestite (totali):
                        </div>
                        <div class="row-stats-index__value">
                            {{stats.quote_requests.unhandled}}
                        </div>
                    </li>
                    <li v-if="moduleActive(app, 'booking')" class="row-stats-index">
                        <div class="row-stats-index__label">
                            Prenotazioni non gestite (totali):
                        </div>
                        <div class="row-stats-index__value">
                            {{stats.bookings.unhandled}}
                        </div>
                    </li>
                    <li v-if="moduleActive(app, 'newsletter')" class="row-stats-index">
                        <div class="row-stats-index__label">
                            Campagne inviate (6 mesi):
                        </div>
                        <div class="row-stats-index__value">
                            {{stats.newsletter.sent}}
                        </div>
                    </li>
                </ul>
            </div>
        </details>
    </div>
</template>

<script>
import EventBus from "../EventBus";

export default {
    props: ['app'],
    computed: {
        stats() {
            return this.app.latest_usage_stats.data;
        },
        unreadQuotes() {
            return this.stats.quotes.sent - this.stats.quotes.read;
        },
        unreadEmails() {
            return this.stats.outbound_emails.total - this.stats.outbound_emails.read;
        },
        unreadQuoteEmails() {
            return this.stats.outbound_emails.quotes_total - this.stats.outbound_emails.quotes_read;
        },
        unreadQuotesPercentage() {
            if (!this.stats.quotes.sent) {
                return 0;
            }
            return Math.round(this.unreadQuotes / this.stats.quotes.sent * 1000) / 10;
        }
    },
    methods: {
        moduleActive(app, moduleName) {
            return app.modules && app.modules.indexOf(moduleName) !== -1;
        },
        unreadAlertClass(sent, unreadPercentage) {
            if (unreadPercentage === 100) {
                return 'text-warning font-weight-bold'
            }
            if (sent < 20) {
                return '';
            }
            if (unreadPercentage >= 25) {
                return 'text-danger font-weight-bold'
            }
            if (unreadPercentage >= 15) {
                return 'text-warning font-weight-bold'
            }
            return '';
        },
        showStats(app) {
            EventBus.$emit('stats:show', app);
        },
    }
}
</script>

<style>
.row-stats-index {
    border-bottom: 1px solid #ddd;
    display: flex;
    justify-content: space-between;
    padding: 2px;
}

.row-stats-index__label {
    display: flex;
    flex-direction: column;
    justify-content: center
}

.row-stats-index__value {
    line-height: 12px;
    display: inline-block;
    font-weight: 600;
    padding: 2px;
    text-align: right;
}

.row-stats-index__value--secondary {
    color: #999;
    font-size: 9px;
}
</style>
