<template>
    <div>
        <div class="d-flex justify-content-between">
            <div>
                <h2 class="mb-5 mt-5 font-weight-light">
                    <i class="fab fa-medapps mr-2"></i>
                    Installazioni di ComodoHotel
                    <small><span class="badge badge-success">{{list.length}}</span></small>
                </h2>
            </div>
            <div class="d-flex flex-column justify-content-center">
                <a href="/admin/applications/create" class="btn btn-success btn-rounded btn-sm"
                   @click.prevent="createApp">
                    <i class="fas fa-plus mr-1"></i> Aggiungi
                </a>
            </div>
        </div>

        <div class="mb-3">
            <div class="input-group">
                <input type="search" v-model="search" class="form-control" placeholder="Cerca...">
                <div class="input-group-append dropdown">
                    <a href="#" class="btn btn-secondary" role="button" data-toggle="dropdown" id="filters_dropdown"
                       aria-haspopup="true" aria-expanded="false" ref="filtersDropdown">
                        <i class="fas fa-filter"></i> <i class="fa fa-caret-down"></i>
                    </a>

                    <div class="dropdown-menu  dropdown-menu-right" aria-labelledby="filters_dropdown">
                        <a href="#" class="dropdown-item" @click="filters.hide_disabled = !filters.hide_disabled">
                            <span v-if="filters.hide_disabled">
                                <i class="fas fa-check fa-fw mr-2 text-success"></i>
                                Visualizza
                            </span>
                            <span v-else>
                                <i class="fas fa-times fa-fw mr-2 text-danger"></i>
                                Nascondi
                            </span>
                            disabilitati
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <div class="card" v-if="!loading">
            <div class="card-body">
                <div class="table-responsive">
                    <table class="table table-striped app-list--table bg-white">
                        <thead>
                        <tr>
                            <th>
                                Applicazione
                            </th>
                            <th>Moduli</th>
                            <th>Pings</th>
                            <th class="text-center">Stats (5 giorni)</th>
                            <th class="text-center">
                                Versione
                                <a href="#" @click="sortBy('version')">
                                    <i class="fas fa-sort" v-if="!sort.version"></i>
                                    <i class="fas fa-caret-down" v-if="sort.version === 'asc'"></i>
                                    <i class="fas fa-caret-up" v-if="sort.version === 'desc'"></i>
                                </a>
                            </th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="app in list" v-bind:key="app.id">
                            <td>
                                <a class="text-info font-weight-bold no-underline" :href="app.admin_uri"
                                   target="_blank">
                                    {{app.domain}}
                                </a>

                                <div v-if="app.languages">
                                    <small class="font-weight-bold">
                                        Lingue attive:
                                    </small>
                                    <div>
                                        <img src="/img/flags/it.svg" alt="it" class="flag">
                                        <img :src="'/img/flags/' + lang + '.svg'" :alt="lang" class="flag"
                                             v-for="lang in app.languages" v-if="!!lang">
                                    </div>
                                </div>
                            </td>
                            <td style="max-width: 200px;">
                                <template v-if="app.modules">
                                    <small v-if="app.modules.length">
                                        {{app.modules.join(', ')}}.
                                    </small>
                                    <small v-else>
                                        <span class="badge badge-danger">
                                            Nessun modulo attivo
                                        </span>
                                    </small>
                                </template>
                            </td>
                            <td>
                                <div v-for="(pingEnabled, pingName) in app.pings" v-if="pingEnabled">
                                    <small>{{pingName}}</small>
                                </div>
                            </td>
                            <td>
                                <app-row-stats :app="app"/>
                            </td>
                            <td class="text-center">
                                <div>
                                    <small>

                                        <i v-if="app.start_upgrading_at"
                                           class="fas fa-spinner fa-spin text-success"></i>

                                        <span v-else>
                                            <span v-if="app.version">
                                                {{app.version}}
                                                <span class="badge badge-danger" v-if="app.deploy_branch">{{app.deploy_branch}}</span>
                                                <span v-if="app.last_upgrade_log">
                                                    <i class="fas fa-info-circle ml-1 text-muted cursor-pointer"
                                                       @click="showUpgradeLog(app.id)"></i>
                                                </span>
                                            </span>

                                            <span v-if="app.last_upgrade_error" class="text-danger">
                                                <i class="fas fa-exclamation-triangle cursor-pointer"
                                                   @click="showUpgradeError(app.id)"></i>
                                            </span>

                                            <span v-if="!app.version && !app.last_upgrade_error">
                                                •
                                            </span>
                                        </span>

                                    </small>
                                </div>
                            </td>
                            <td class="text-right" style="white-space: nowrap">
                                <div class="dropdown">
                                    <a class="btn btn-link btn-sm text-muted" ref="dropdown"
                                       href="#" role="button" :id="'actions-dd'+app.id" data-toggle="dropdown"
                                       aria-haspopup="true" aria-expanded="false">
                                        <i class="fas fa-ellipsis-v"></i>
                                    </a>

                                    <div class="dropdown-menu" :aria-labelledby="'actions-dd'+app.id">
                                        <a class="dropdown-item" href="#" @click.prevent="editApp(app.id)">
                                            <i class="fas fa-pencil-alt fa-fw mr-2 text-primary"></i>
                                            Modifica
                                        </a>
                                        <a class="dropdown-item" :href="app.admin_uri" target="_blank">
                                            <i class="fas fa-user-shield fa-fw text-warning mr-2"></i>
                                            Amministra
                                        </a>
                                        <a href="#" @click.prevent="upgradeApp(app.id)" class="dropdown-item">
                                            <i class="fas fa-arrow-alt-circle-up fa-fw mr-2 text-success"></i>
                                            Aggiorna
                                        </a>
                                        <a class="dropdown-item" href="#" @click.prevent="deleteApp(app.id)">
                                            <i class="fas fa-times text-danger fa-fw mr-2"></i>
                                            Elimina
                                        </a>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div v-else class="text-muted">
            <i class="fas fa-spin fa-spinner mr-2"></i> Attendere, caricamento in corso...
        </div>
        <app-create-modal></app-create-modal>
        <pre-modal></pre-modal>
        <app-show-stats></app-show-stats>
    </div>
</template>

<script>
    import EventBus from "../EventBus";
    import AppCreateModal from "./AppCreateModal";
    import * as compareVersions from 'compare-versions';
    import AppRowStats from "./AppRowStats";

    export default {
        async mounted() {
            setTimeout(() => $(this.$refs.filtersDropdown).dropdown(), 0);

            await axios.get('/admin/api/applications').then(r => this.applications = r.data.data);
            this.loading = false;
            EventBus.$on('applications:created', app => this.applications.push(app));
            EventBus.$on('applications:updated', app => this.updateApp(app));

            window.Echo
                .private('notifications')
                .listen('ApplicationUpdated', ({application}) => {
                    EventBus.$emit('applications:updated', application);
                })
                .listen('ApplicationUpgraded', ({application}) => {
                    this.$notify({
                        group: 'default',
                        type: 'success',
                        title: 'Applicazione aggiornata!',
                        text: `${application.domain} è stato aggiornato correttamente alla versione ${application.version}.`,
                        duration: -1
                    });
                })
                .listen('ApplicationUpgradeFailed', ({application}) => {
                    this.$notify({
                        group: 'default',
                        type: 'error',
                        title: 'Aggiornamento fallito!',
                        text: `L'aggiornamento di ${application.domain} è fallito.`,
                        duration: -1
                    });
                })
        },
        components: {
            AppCreateModal,
            AppRowStats
        },
        data() {
            return {
                applications: [],
                loading: true,
                search: null,
                sort: {
                    version: null
                },
                filters: {
                    hide_disabled: false
                }
            }
        },
        methods: {
            upgradeApp(appId) {
                let app = this.findApp(appId);
                if (!confirm(`Sicuro di voler aggiornare ${app.domain} all'ultima versione?`)) {
                    return;
                }
                axios.post(`/admin/api/applications/${appId}/upgrade`);
                this.findApp(appId).start_upgrading_at = new Date();
            },
            deleteApp(appId) {
                let app = this.findApp(appId);
                if (!confirm(`Sicuro di voler eliminare ${app.domain}?`)) {
                    return;
                }
                axios.delete(`/admin/api/applications/${appId}`);
                let key = this.findAppKey(appId);
                this.applications.splice(key, 1);
            },
            createApp() {
                EventBus.$emit('applications:create')
            },
            editApp(appId) {
                EventBus.$emit('applications:edit', appId)
            },
            findApp(appId) {
                return this.applications.find(a => a.id === appId);
            },
            findAppKey(appId) {
                return Object.keys(this.applications).find(k => this.applications[k].id === appId);
            },
            updateApp(app) {
                this.applications.splice(this.findAppKey(app.id), 1, app);
            },
            sortBy(field) {
                if (this.sort[field]) {
                    this.sort[field] = this.sort[field] === 'asc' ? 'desc' : null;
                } else {
                    this.sort[field] = 'asc';
                }
            },
            reloadDropdowns() {
                setTimeout(() => $(this.$refs.dropdown).dropdown(), 0);
            },
            showUpgradeError(appId) {
                EventBus.$emit('modal:pre', '<warning>' + this.findApp(appId).last_upgrade_error + '</warning>')
            },
            showUpgradeLog(appId) {
                EventBus.$emit('modal:pre', this.findApp(appId).last_upgrade_log)
            },
        },
        computed: {
            list() {
                let applications = this.applications;

                if (this.filters.hide_disabled) {
                    applications = applications.filter(a => a.modules && a.modules.length)
                }

                // Default sort by last id
                applications = applications.sort((a, b) => b.id > a.id ? 1 : -1);

                if (this.search) {
                    applications = applications.filter(a => a.domain.search(this.search) !== -1)
                }

                for (let prop in this.sort) {
                    if (this.sort[prop] === 'asc') {
                        applications = applications.sort((a, b) => compareVersions(a[prop] ? a[prop] : '0', b[prop] ? b[prop] : '0'));
                    } else if (this.sort[prop] === 'desc') {
                        applications = applications.sort((a, b) => compareVersions(b[prop] ? b[prop] : '0', a[prop] ? a[prop] : '0'));
                    }
                }

                this.reloadDropdowns();

                return applications;
            }
        }
    }
</script>

<style lang="scss">
    .app-list--table {
        td, th {
            vertical-align: middle;
        }

        th {
            border-top: none !important;
        }

        .flag {
            height: 10px;
            margin-right: 4px;
        }
    }

    .stats-list {
        li {
            display: flex;
            justify-content: space-between;
        }
    }

    .stats-btn-details {
        display: block;
        margin-top: 5px;
        background: #6c757c;
        color: #fff !important;
        border-radius: 2px;
        text-align: center;

        &:hover {
            background-color: #768088;
        }
    }
</style>
