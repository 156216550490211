<template>
    <div>
        <div class="d-flex justify-content-between">
            <div>
                <h2 class="mb-5 mt-5 font-weight-light">
                    <i class="fab fa-medapps mr-2"></i>
                    Esporta per modello AI
                </h2>
            </div>
            <div class="mb-5 mt-5 font-weight-light">
                <template v-if="!saving">
                    <a href="#" class="btn btn-primary" @click.prevent="exportData">
                        <i class="fa fa-file-export mr-1"></i>
                        Esporta
                    </a>
                    <a href="#" class="btn btn-success" @click.prevent="save">
                        <i class="fa fa-save mr-1"></i>
                        Salva
                    </a>
                </template>
                <div v-else class="text-muted">
                    <i class="fa fa-spin fa-spinner mr-1"></i>
                    Operazione in corso...
                </div>
            </div>
        </div>

        <div class="card mb-3" v-if="Object.keys(treatmentsToBeMapped).length">
            <div class="card-header cursor-pointer" @click="showMappings=!showMappings">
                Trattamenti non mappati
                <span v-if="unmappedTreatmentsCount" class="badge badge-danger">
                    {{unmappedTreatmentsCount}}
                </span>
                <div class="float-right">
                    <i class="fa fa-caret-up" v-if="showMappings"></i>
                    <i class="fa fa-caret-down" v-if="!showMappings"></i>
                </div>
            </div>
            <div class="card-body" v-if="showMappings">
                <table class="table table-striped app-list--table bg-white">
                    <thead>
                    <tr>
                        <th>Trattamenti non mappati</th>
                        <th>Mappatura</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(value, unmappedTreatment) in treatmentsToBeMapped">
                        <td>
                            {{unmappedTreatment}}
                        </td>
                        <td>
                            <select v-model="treatmentsToBeMapped[unmappedTreatment]" class="form-control">
                                <option :value="null">Nessuna</option>
                                <option :value="treatment" v-for="treatment in treatments">{{treatment}}</option>
                            </select>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="card" v-if="!loading">
            <div class="card-header">
                Installazioni
            </div>
            <div class="card-body">
                <div class="table-responsive">
                    <table class="table table-striped app-list--table bg-white">
                        <thead>
                        <tr>
                            <th class="text-center">Esporta</th>
                            <th>Hotel</th>
                            <th>Stelle</th>
                            <th>Località</th>
                            <th class="text-center">Piscina</th>
                            <th class="text-center">Fronte mare</th>
                            <th class="text-center">Ultimo sync</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(app, key) in applications"
                            v-bind:key="app.id"
                            :class="{'has-error': errors.includes(key)}"
                        >
                            <td class="text-center">
                                <input type="checkbox" v-model="app.ai_settings.enabled">
                            </td>
                            <td>
                                <a class="text-info font-weight-bold no-underline" :href="app.admin_uri"
                                   target="_blank">
                                    {{app.domain}}
                                </a>
                            </td>
                            <td class="text-center">
                                <select class="form-control" v-model="app.ai_settings.stars">
                                    <option :value="null">Seleziona</option>
                                    <option :value="star" v-for="star in stars">{{star}}</option>
                                </select>
                            </td>
                            <td>
                                <select class="form-control" v-model="app.ai_settings.place">
                                    <option :value="null">Seleziona</option>
                                    <option>Pesaro</option>
                                    <option>Gabicce</option>
                                    <option>Cattolica</option>
                                    <option>Misano</option>
                                    <option>Riccione</option>
                                    <option>Rimini sud</option>
                                    <option>Rimini centro</option>
                                    <option>Rimini nord</option>
                                    <option>Igea Marina</option>
                                    <option>Bellaria</option>
                                    <option>San Mauro Mare</option>
                                    <option>Gatteo Mare</option>
                                    <option>Cesenatico</option>
                                    <option>Cervia</option>
                                    <option>Milano Marittima</option>
                                </select>
                            </td>
                            <td class="text-center">
                                <input type="checkbox" v-model="app.ai_settings.pool">
                            </td>
                            <td class="text-center">
                                <input type="checkbox" v-model="app.ai_settings.sea_front">
                            </td>
                            <td class="text-center">
                                {{app.last_download}}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div v-else class="text-muted">
            <i class="fas fa-spin fa-spinner mr-2"></i> Attendere, caricamento in corso...
        </div>
    </div>
</template>

<script>
export default {
    async mounted() {
        this.treatmentsToBeMapped = this.unmappedTreatments;
        await axios
            .get('/admin/api/applications')
            .then(r => {
                let applications = r.data.data;
                const availableIds = this.availableFiles.map(af => af.id);
                applications = applications.filter(a => availableIds.includes(a.id));

                applications.forEach(a => a.ai_settings = a.ai_settings || {
                    enabled: false,
                    stars: null,
                    place: null,
                    sea_front: false,
                    pool: false
                })

                applications.forEach(a => a.last_download = this.availableFiles.find(af => af.id === a.id).date)

                this.applications = applications
            });
        this.loading = false;
    },
    props: [
        'availableFiles',
        'unmappedTreatments',
        'treatments',
    ],
    data() {
        return {
            applications: [],
            loading: true,
            errors: [],
            saving: false,
            showMappings: false,
            treatmentsToBeMapped: {}
        }
    },
    methods: {
        async save() {
            this.errors = [];
            this.saving = true;
            try {
                await axios.post('/admin/ai', {
                    applications: this.applications,
                    unmappedTreatments: this.treatmentsToBeMapped,
                });
                this.handleSuccess();
            } catch (e) {
                this.handleError(e);
            }
            this.saving = false;
        },
        async exportData() {
            this.errors = [];
            let response;

            try {
                response = await axios.post('/admin/ai/export', {
                    applications: this.applications,
                    unmappedTreatments: this.treatmentsToBeMapped,
                });
            } catch (e) {
                this.handleError(e);
                this.saving = false;
                return;
            }

            this.saving = false;

            this.handleSuccess();

            this.downloadFile(response);
        },
        downloadFile(response) {
            const headers = response.headers;
            const blob = new Blob([response.data], {type: headers['content-type']});
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = "export";
            link.click();
        },
        handleSuccess() {
            this.$notify({
                group: 'default',
                type: 'success',
                title: 'Evviva!',
                text: 'Operazione avvenuta con successo.'
            });
        },
        handleError(e) {
            this.errors = [...new Set(
                Object
                    .keys(e.response.data.errors)
                    .map(field => parseInt(field.match(/[0-9]+/)[0]))
            )];

            this.$notify({
                group: 'default',
                type: 'error',
                title: 'Errore di validazione',
                text: 'Alcuni dati mancanti per procedere all\'export.'
            });
        }
    },
    computed: {
        stars() {
            return [...Array(5).keys()].map(s => s + 1);
        },
        unmappedTreatmentsCount(){
            return Object.values(this.treatmentsToBeMapped).filter(v => !v).length
        }
    }
}
</script>

<style>
tr.has-error {
    background-color: #eccccc !important;
}
</style>
