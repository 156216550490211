<template>
    <div v-if="pings">
        <div class="modal fade" id="AppCreateModal" tabindex="-1" role="dialog" aria-labelledby="AppCreateModalLabel"
             aria-hidden="true" ref="modal">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="AppCreateModalLabel">
                            <span v-if="action === 'store'">Aggiungi</span>
                            <span v-if="action === 'update'">Modifica</span> applicazione
                        </h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="form-group">
                            <label for="uri">Indirizzo dell'applicazione</label>
                            <input type="url" id="uri" v-model="app.uri" class="form-control">
                        </div>

                        <div class="form-group">
                            <label for="personal_access_token">Chiave di accesso personale</label>
                            <input type="text" id="personal_access_token" placeholder="•••"
                                   v-model="app.personal_access_token" class="form-control">
                        </div>

                        <div class="p-3 bg-light">
                            <p class="lead">Abilita pings per le seguenti azioni</p>
                            <div v-for="(pingName, ping) in pings" class="custom-control custom-checkbox">
                                <input type="checkbox"
                                       class="custom-control-input"
                                       v-model="app.pings[ping]"
                                       :id="ping + '_checkbox'"
                                >
                                <label class="custom-control-label" :for="ping + '_checkbox'">
                                    {{pingName}}
                                </label>
                            </div>
                        </div>

                        <div>
                            <p class="lead mt-3">Configurazione Websocket</p>
                            <pre class="p-3 bg-dark text-white">PUSHER_APP_ID={{app.id}}
PUSHER_APP_KEY={{app.public_key}}
PUSHER_APP_SECRET={{app.secret_key}}</pre>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">
                            Chiudi
                        </button>
                        <btn icon="plus" :loading="saving" @click.native="saveOrCreate">
                            <span v-if="action === 'store'">Aggiungi</span>
                            <span v-if="action === 'update'">Salva</span>
                        </btn>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import EventBus from "../EventBus";

    export default {
        mounted() {

            EventBus.$on('applications:create', async () => {
                this.action = 'store';

                this.resetForm();

                await this.loadPings();

                for (let prop in this.pings) {
                    this.$set(this.app.pings, prop, false)
                }

                setTimeout(() => $(this.$refs.modal).modal('show'), 0);
            });

            EventBus.$on('applications:edit', async (appId) => {
                this.action = 'update';
                await this.loadPings();

                for (let prop in this.pings) {
                    this.$set(this.app.pings, prop, false)
                }

                let {data} = await axios.get(`/admin/api/applications/${appId}`);
                this.app = data.data;

                setTimeout(() => $(this.$refs.modal).modal('show'), 0);
            });

        },
        data() {
            return {
                app: {
                    personal_access_token: null,
                    uri: null,
                    pings: {}
                },
                pings: null,
                saving: false,
                action: null
            }
        },
        methods: {
            resetForm() {
                this.app = {
                    personal_access_token: null,
                    uri: null,
                    pings: {}
                };
            },
            async loadPings() {
                if (!this.pings) {
                    const response = await axios.get('/admin/api/ping-endpoints');

                    this.pings = response.data.data;
                }
            },
            async saveOrCreate() {
                this.saving = true;
                try {
                    if (this.action === 'store') {
                        const {data} = await axios
                            .post('/admin/api/applications', this.app);

                        EventBus.$emit('applications:created', data.data);
                    } else {
                        const {data} = await axios
                            .put(`/admin/api/applications/${this.app.id}`, this.app);

                        EventBus.$emit('applications:updated', data.data);
                    }
                    $(this.$refs.modal).modal('hide');
                } catch ({response}) {
                    this.saving = false;
                    if (response && response.status === 422) {
                        let text = Object.keys(response.data.errors).reduce((r, k) => r + response.data.errors[k][0], '');

                        this.$notify({
                            group: 'default',
                            type: 'error',
                            title: 'Errore di validazione',
                            text
                        });
                    }
                }
                this.saving = false;
            }
        }
    }
</script>

<style lang="scss">
    .app-list--table {
        td, th {
            vertical-align: middle;
        }
    }
</style>