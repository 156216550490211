<template>
    <button class="btn btn-primary" :disabled="loading">
        <template v-if="!loading">
            <i v-if="icon" class="fas mr-2" :class="'fa-' + icon"></i>
            <slot></slot>
        </template>
        <template v-else>
            <i class="fas fa-spin fa-spinner mr-2"></i>
            Attendere prego ...
        </template>
    </button>
</template>

<script>
    export default {
        mounted() {
            console.log('Component mounted.')
        },
        props: {
            loading: {
                default: false,
                type: Boolean
            },
            icon: {
                default: null,
                type: String
            }
        }
    }
</script>
