<template>
    <div>
        <div class="modal fade" id="PremoDal" tabindex="-1" role="dialog" aria-labelledby="PreModalTitle"
             aria-hidden="true" ref="modal">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="PreModalTitle">
                            Output
                        </h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <pre v-html="html" class="dark-code"></pre>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">
                            Chiudi
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import EventBus from "../EventBus";

    export default {
        mounted() {
            EventBus.$on('modal:pre', html => {
                this.html = html;
                setTimeout(() => $(this.$refs.modal).modal('show'), 0);
            });
        },
        data() {
            return {
                html: null
            }
        }
    }
</script>

<style lang="scss">
    .dark-code {
        background: #454545;
        color: #dad8b8;
        padding: 1rem;
        max-height: 600px;
        warning {
            color: #e84c25;
        }
        success {
            color: #3ae874;
        }
        .console__line {
            &:first-child{
                display: none;
            }
            border-bottom: 1px solid #6d6d6d;
            display: block;
            margin-top: 1rem;
        }
    }
</style>
